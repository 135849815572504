import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

@Injectable(
//     {
//     providedIn: 'root'
// }
)
export class UserChangesCanDeactivateGuard  {

    private discardSubject: Subject<boolean> = new Subject<boolean>();
    public isDirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor() {
        // this.isDirty.subscribe(console.log)

    }
    discard(): void {
        this.discardSubject.next(true);
    }

    canDeactivate(component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.isDirty.value && component.discardModalFlag) {
            component.openDiscardModal();
            return this.discardSubject.asObservable().pipe(first());
        }

        return true;
    }
}
